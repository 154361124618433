.topSection {
	position: relative;
}

.image,
.bgImage {
	position: relative;
	margin-left: -50px;
	margin-top: -15px;
	margin-right: -50px;
	border-radius: 50px 0 0 0;
	overflow: hidden;
	img {
		width: 100%;
		max-height: 562px;
		object-fit: cover;
	}
	h1 {
		position: absolute;
		bottom: 0;
		left: 70px;
		color: rgb(255, 255, 255);
		font-size: 65px;
		// background-color: rgba(0, 0, 0, 0.6);
		border-radius: 25px;
		padding: 5px 10px;
		display: inline-block;
	}
}

.bgImage {
	margin-top: 65px;
	border-radius: 0;
}

.description {
	.subheader {
		font-size: 45px;
		line-height: 3rem;
		font-weight: 700;
		margin: 55px 0 30px 0;
	}

	.videoWrapper {
		margin-top: 60px;
		background: url(../assets/loader_2.gif) center center no-repeat;
		width: 100%;
		height: 600px;
		video {
			margin-top: 25px;
			width: 60%;
			// height: 400px;
			// flex: 1.5;
			height: auto;
			box-shadow: 0px 10px 15px 4px rgba(0, 0, 0, 0.4);
		}
	}
}

.form {
	display: flex;
	margin-top: 50px;
}

@media screen and (max-width: 1900px) {
}

@media screen and (max-width: 1300px) {
	.form {
		display: block;
		top: 350px;
	}

	.contacts {
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 1000px) {
	.image {
		h1 {
			font-size: 48px;
		}
	}

	.description {
		.videoWrapper {
			height: 400px;
			video {
				width: 80%;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.image {
		h1 {
			font-size: 45px;
			width: 80%;
		}
	}

	.description {
		.subheader {
			font-size: 40px;
		}
		.videoWrapper {
			height: 300px;
			flex-direction: column;
			// align-items: center;

			video {
				//   flex: 1;
				width: 80%;
				margin: 25px auto;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.image {
		h1 {
			font-size: 33px;
			width: 70%;
		}
	}

	.description {
		.subheader {
			font-size: 37px;
			margin: 26px 0 6px 0;
		}
		.videoWrapper {
			height: 250px;
			background-size: 30% auto;
			video {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.image {
		h1 {
			font-size: 29px;
		}
	}
	.description {
		.subheader {
			font-size: 33px;
		}
		.videoWrapper {
			height: 200px;
		}
	}
}
