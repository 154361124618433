.home_wrapper {
	// display: flex;
	// flex-direction: column;
	position: relative;
	padding: 1px;
	// z-index: 1;
}

.mainBg {
	position: absolute;
	object-position: right center;
	top: -150px;
	left: -50px;
	right: -50px;
	width: 111%;
	// height: 100vh;
	object-fit: cover;
	z-index: 0; /* Чтобы изображение было на заднем плане */

	&__notebook {
		position: absolute;
		width: 43vw;
		// object-position: right center;
		right: -50px;
		top: 65px;
		z-index: 0;
	}

	&__iphone {
		position: absolute;
		width: 187px;
		top: 10px;
		right: 10px;
		z-index: 0;
	}
}

// .background_image {
//   position: absolute;
//   top: -15px;
//   left: -50px;
//   right: -50px;
//   // width: 100%;
//   background-size: 130%;
//   height: 100%;
//   background-image: url('../assets/mainPage_bg.png');
//   // background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center center;
//   z-index: 0; /* Чтобы фоновая картинка была позади контента */
// }

.header {
	&__design {
		margin-top: 15px;
	}

	&__technology {
		margin-top: 15px;
	}
}

.title1,
.title2,
.title3 {
	display: inline-block;
	font-size: 100px;
	font-weight: 800;
	margin: 0;
	background: rgb(37, 39, 42);
	// background: linear-gradient(103deg, rgba(37, 39, 42, 1) 13%, rgba(119, 125, 134, 1) 25%);
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	filter: drop-shadow(4px 6px 2px rgba(58, 63, 69, 0.2941176471));
	position: relative;
	overflow: hidden;
}
.header_wrapper {
	margin-top: 120px;
	position: relative;
	overflow: hidden;

	&_reversed {
		text-align: right;
	}
}

// .header_wrapper .flare {
//   position: absolute;
//   top: 0;
//   height: 100%;
//   width: 45px;
//   transform: skewX(-45deg); // Наклон
//   animation: flareAnimation;
//   left: -150%;
//   background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4));
//   animation: flareAnimation 3s infinite linear; // Время и тип анимации можно менять
//   z-index: 5;
// }

// @keyframes flareAnimation {
//   0% {
//     left: -80%;
//   }
//   100% {
//     left: 80%;
//   }
// }

//

// .title1 {
//   background: rgb(37, 39, 42);
//   background: linear-gradient(
//     107deg,
//     rgb(37, 39, 42) 18%,
//     rgb(75, 78, 84) 68%,
//     rgb(119, 125, 134) 97%
//   );
//   -webkit-background-clip: text;
//   &:hover {
//     background: rgb(173, 195, 215);
//     background: linear-gradient(
//       103deg,
//       rgba(173, 195, 215, 1) 25%,
//       rgba(200, 217, 233, 1) 53%,
//       rgba(226, 226, 226, 1) 93%
//     );
//     -webkit-background-clip: text;
//   }

// }

// .title2 {
//   margin-left: 100px;
//   background: rgb(37, 39, 42);
//   background: linear-gradient(
//     107deg,
//     rgba(37, 39, 42, 1) 17%,
//     rgba(75, 78, 84, 1) 50%,
//     rgba(119, 125, 134, 1) 71%
//   );
//   -webkit-background-clip: text;
//   &:hover {
//     background: rgb(173, 195, 215);
//     background: linear-gradient(90deg, rgb(173, 195, 215) 6%, rgb(200, 217, 233) 20%);
//     -webkit-background-clip: text;
//   }

//   &_reversed {
//     margin-left: 0;
//     margin-right: 100px;
//   }
// }
// .title3 {
//   background: rgb(37, 39, 42);
//   background: linear-gradient(
//     129deg,
//     rgba(37, 39, 42, 1) 17%,
//     rgba(75, 78, 84, 1) 50%,
//     rgba(161, 168, 180, 1) 83%
//   );
//   -webkit-background-clip: text;
//   &:hover {
//     background: rgb(173, 195, 215);
//     background: linear-gradient(
//       162deg,
//       rgb(173, 195, 215) 30%,
//       rgb(200, 217, 233) 55%,
//       rgb(226, 226, 226) 88%
//     );
//     -webkit-background-clip: text;
//   }

//   &_reversed {
//     margin-left: 0;
//     margin-right: 161px;
//   }
// }

.flag2 {
	position: absolute;

	z-index: 3;
}

.flag2 {
	width: 200px;
	height: 200px;
	left: -93px;
	bottom: -179px;
}

.text {
	position: relative;
	width: 410px;
	margin-top: 45px;
	font-size: 20px;
	font-weight: 500;
	max-width: 750px;
	line-height: 1.9rem;

	&_reversed {
		text-align: right;
	}
}

.portfolio {
	margin-top: 150px;
	position: relative;
	z-index: 5;

	&__header {
		display: inline-block;
		font-size: 28px;
		font-style: normal;
		font-weight: 500;
		border: 3px solid black;
		padding: 6px 33px;
		border-radius: 36px;
	}
}

.portfolioCards {
	margin-top: 20px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: auto;
	//   grid-auto-rows: 376px;
	//   grid-auto-rows: minmax(376px, auto);
	grid-column-gap: 22px;
	grid-row-gap: 22px;
	grid-auto-flow: row;

	grid-template-areas:
		"one two three"
		"four five five"
		"six seven eight"
		"nine ten ten"
		"eleven ten ten";
	a {
		position: relative;
		border-radius: 30px;
		overflow: hidden;
		// max-width: 630px;
		// width: 430px;
		// height: 292px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 0.8s;

		&:hover {
			transform: scale(1.1);
		}
	}

	span {
		position: absolute;
		right: 17px;
		bottom: 19px;
		font-size: 24px;
		color: #fff;
		border-radius: 22px;
		padding: 8px 18px;
		background-color: #272727;
	}
}

.item1 {
	grid-area: one;
}
.item2 {
	grid-area: two;
}
.item3 {
	grid-area: three;
}
.item4 {
	grid-area: four;
}
.item5 {
	grid-area: five;
}
.item6 {
	grid-area: six;
}
.item7 {
	grid-area: seven;
}
.item8 {
	grid-area: eight;
}
.item9 {
	grid-area: nine;
}
.item10 {
	grid-area: ten;
}
.item11 {
	grid-area: eleven;
}
.item6,
.item7,
.item8,
.item9,
.item10,
.item11,
.item12,
.item13 {
	overflow: hidden;
	border-radius: 25px;
}

.form {
	display: flex;
	margin-top: 100px;
	margin-bottom: 40px;
}

@media screen and (min-width: 1900px) {
	.portfolio {
		margin-top: 355px;
	}
}

@media screen and (max-width: 1300px) {
	.form {
		display: block;
	}
}

@media screen and (max-width: 1260px) {
	.header {
		&__strategy,
		&__design,
		&__technology {
			img {
				height: 52px;
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.mainBg {
		width: 112%;
	}
	.flag1 {
		display: none;
	}

	.portfolio {
		margin-top: 100px;
	}
}

@media screen and (max-width: 1000px) {
	.title1,
	.title2,
	.title3 {
		font-size: 80px;
	}

	.header {
		&__strategy,
		&__design,
		&__technology {
			img {
				height: 42px;
			}
		}
	}

	.mainBg {
		width: 115%;
		top: -73px;
	}

	.portfolioCards {
		span {
			font-size: 16px;
			right: 9px;
			bottom: 13px;
		}
	}

	.emoji {
		bottom: -11px;
		left: 504px;
	}
	.flag2 {
		width: 80px;
		height: 80px;
		right: 75px;
		bottom: 303px;
		left: auto;
	}

	.form {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 800px) {
	.home_wrapper {
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 1;
	}

	.header {
		&__strategy,
		&__design,
		&__technology {
			img {
				height: 39px;
			}
		}
	}

	.header_wrapper {
		margin-left: 5%;
	}

	.mainBg {
		width: 111%;
		top: -57px;
	}

	.title1,
	.title2,
	.title3 {
		font-size: 70px;
	}

	.title1 {
		margin: 0;
		align-self: start;
	}

	.text {
		margin-left: 0;
		display: flex;
		justify-content: flex-end;
		align-self: end;
		max-width: 80%;
	}

	.portfolio {
		margin-top: 70px;
	}
}

@media screen and (max-width: 670px) {
	.mainBg {
		&__iphone {
			position: absolute;
			width: 50%;
			top: 20px;
			z-index: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}

	.header_wrapper {
		margin-top: 621px;
	}

	.header {
		&__strategy,
		&__design,
		&__technology {
			img {
				height: 38px;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.title1,
	.title2,
	.title3 {
		font-size: 51px;
	}

	.header {
		&__strategy,
		&__design,
		&__technology {
			margin-top: 0;
		}
	}

	.mainBg {
		width: 116%;
	}

	.portfolioCards {
		margin-top: 0;
		display: block;
		img {
			margin-top: 40px;
		}
	}

	.flag2 {
		display: none;
	}

	.text {
		max-width: 90%;
		margin-top: 50px;
	}

	.form {
		margin-top: 50px;
	}
}

@media screen and (max-width: 520px) {
	.header_wrapper {
		margin-top: 500px;
	}
}

@media screen and (max-width: 460px) {
	.header_wrapper {
		margin-top: 434px;
	}

	.header {
		&__strategy,
		&__design,
		&__technology {
			img {
				height: 31px;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.header_wrapper {
		margin-left: 0;
		margin-top: 377px;
	}

	.mainBg {
		width: 120%;
	}

	.header {
		&__strategy,
		&__design,
		&__technology {
			img {
				height: 27px;
			}
		}
	}

	.title1,
	.title2,
	.title3 {
		font-size: 40px;
	}

	.text {
		max-width: 100%;
		margin-top: 25px;
	}
}

@media screen and (max-width: 350px) {
	.header_wrapper {
		margin-top: 337px;
	}
}
