.topSection {
  position: relative;
}

.image {
  position: relative;
  margin-left: -50px;
  margin-top: -15px;
  margin-right: -50px;
  border-radius: 50px 0 0 0;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 562px;
    object-fit: cover;
  }
  h1 {
    position: absolute;
    bottom: 0;
    left: 70px;
    color: rgb(255, 255, 255);
    font-size: 80px;
    // background-color: rgba(255, 255, 255, 0.6);
    border-radius: 25px;
    padding: 5px 10px;
    display: inline-block;
  }
}

.description {
  margin-top: 55px;
  position: relative;
  height: calc(100% + 100px);
  //   overflow: hidden;
  h3 {
    font-size: 40px;
    margin-top: 25px;
  }

  article {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7rem;
    max-width: 1000px;
  }
}

.topWrapper {
  margin-top: 30px;
  display: flex;
  gap: 30px;
  align-items: flex-end;
  img {
    width: 45%;
    max-width: 1000px;
  }
  p {
    font-size: 22px;
    font-weight: 400;
    color: #a8a8a8;
  }
}

.botWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
  img:nth-child(2) {
    max-width: 907px;
    width: 40%;
  }
  img:nth-child(1) {
    max-width: 362px;
    margin-top: 65px;
    width: 16%;
  }
}

.form {
  display: flex;
  margin-top: 50px;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .form {
    display: flex;
    flex-direction: column;
    // top: 350px;
  }

  .botWrapper {
    img:nth-child(1) {
      margin-top: 40px;
    }
  }

  .contacts {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .image {
    h1 {
      width: 89%;
    }
  }

  .botWrapper {
    img:nth-child(1) {
      width: 24%;
    }

    img:nth-child(2) {
      width: 60%;
    }
  }
}

@media screen and (max-width: 800px) {
  .image {
    h1 {
      width: 82%;
      font-size: 56px;
    }
  }

  .description {
    article {
      font-size: 22px;
    }
  }

  .topImgWrapper {
    flex-direction: column;
    align-items: center;
  }

  .imageLittle {
    width: 100%;

    p {
      margin-right: 15px;
    }
  }

  .botImage {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .image {
    h1 {
      left: 47px;
      width: 79%;
      font-size: 46px;
      bottom: -21px;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      margin-top: 15px;
      font-size: 19px;
    }
    h3 {
      font-size: 38px;
    }
  }

  .topWrapper {
    flex-direction: column;

    img {
      width: 100%;
    }
    p {
    }
  }

  .botWrapper {
    flex-direction: column;
    img:nth-child(1) {
      width: 70%;
    }

    img:nth-child(2) {
      width: 100%;
    }
  }
}

@media screen and (max-width: 415px) {
  .image {
    h1 {
      width: 76%;
      font-size: 32px;
      bottom: -21px;
    }
  }

  .imageLittle {
    width: 100%;

    p {
      margin-right: 0;
      font-size: 18px;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      font-size: 18px;
    }
    h3 {
      font-size: 34px;
    }
  }
}
