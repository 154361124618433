.form {
  &__wrapper {
    min-width: 205px;
    flex: 1;
    margin-left: 40px;
  }

  &__element {
    padding: 20px 30px;
    background-color: #131415;
    border-radius: 20px;
  }

  &__contact {
    color: #fff;
    font-size: 30px;
    font-weight: 700;
  }

  &__discuss,
  &__coop {
    margin-top: 20px;
    color: #858585;
    font-style: 15px;
  }

  &__email,
  &__phone {
    color: #fff;
    margin-top: 10px;
    font-style: 20px;
  }

  &__phone {
    margin-top: 15px;
  }

  &__bottom {
    height: 216px;
    margin-top: 20px;
    background-color: #131415;
    border-radius: 20px;

    div {
      width: 100%;
      height: 100%;
      // margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      height: 50%;
      width: 50%;
      object-fit: contain;
    }
  }
}

.contactImg {
  display: flex;
  margin-top: 10px;
  align-items: center;

  img {
    color: #fff;
    width: 30px;
    height: 30px;
  }

  p {
    margin-left: 10px;
    color: #fff;
  }
}

@media screen and (max-width: 1300px) {
  .form {
    &__wrapper {
      margin-top: 35px;
      margin-left: 0;
      display: flex;
    }

    &__element {
      flex: 0.4;
    }

    &__bottom {
      height: 321px;
      flex: 0.4;
      margin-left: 50px;
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .form {
    &__element {
      flex: 0.5;
    }

    &__bottom {
      flex: 0.5;
    }
  }
}

@media screen and (max-width: 800px) {
  .form {
    &__bottom {
      flex: 0.6;
    }
  }
}

@media screen and (max-width: 576px) {
  .form {
    &__wrapper {
      flex-direction: column;
    }

    &__bottom {
      margin: 35px 0 0 0;
      flex: 0 1 auto;
      height: 216px;
    }
  }
}
