// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './fontStyle.scss'; //это стили для шрифта

* {
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  font-family: 'Montserrat', Roboto, system-ui, Tahoma, sans-serif;
  box-sizing: border-box;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  // input[type='number'] {
  //   -moz-appearance: textfield;
  // }
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 12px;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1 {
  font-size: 48px;
}

h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 30px;
}

a {
  text-decoration: none;
}

.main_wrapper {
  background-color: #c8d9e9;

  // добавил
  overflow: hidden;
}

.wrapper {
  // width: calc(100vw - 100px);
  height: 100%;
  background-color: #fff;
  margin: 10px 0 0 60px;
  padding: 15px 50px 40px 50px;
  border-radius: 50px 0 0 0;
  // max-width: 1400px;
  max-width: 100vw;
  overflow: hidden;

  // поменял
  position: relative;
  // overflow-x: hidden;
}

@media screen and (max-width: 1000px) {
  .main_wrapper {
    background-color: #fff;
  }

  .wrapper {
    margin: 10px 0 0 0;
  }
}

@media screen and (max-width: 800px) {
  .main_wrapper {
    background-color: #fff;
  }

  .wrapper {
    padding: 15px 10px 40px 10px;
  }
}
