.wrapper {
	max-width: 100vw;
}

.card_section {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	gap: 12px 20px;
	justify-content: space-around;
}

.link {
	margin: 20px 0;
	// width: 315px; /* добавил потом чтобы текст не вылезал */
	width: 45%;
}

.link:nth-child(5) {
	margin-right: auto;
	margin-left: 1.75%;
}

.link:hover {
	.card_wrapper img {
		transform: scale(1.2);
		cursor: pointer;
	}
}

.card_wrapper {
	display: block;
	position: relative;
	margin-bottom: 15px;
	width: 100%;
	// width: 315px;
	// height: 200px;
	border-radius: 30px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 0.8s;
	}
}

.service {
	position: absolute;
	bottom: 20px;
	left: 26px;
	color: #fff;
	background-color: #272727;
	padding: 9px 16px;
	// border: 1px solid #fff;
	border-radius: 30px;
}

.name {
	color: #000;
	font-size: 24px;
	font-weight: 600;
	max-width: 300px;
	margin-top: 15px;
}

.form {
	display: flex;
	margin-top: 100px;
}

@media screen and (min-width: 2600px) {
	.link {
		width: 9%;
	}
}

@media screen and (max-width: 1480px) {
	// .link {
	// 	width: 23%;
	// }
}

@media screen and (max-width: 1300px) {
	// .link {
	// 	width: 31%;
	// }

	.form {
		display: block;
	}
}

@media screen and (max-width: 768px) {
	// .card_section {
	// 	width: 85%;
	// 	justify-content: space-between;
	// }
	// .link {
	// 	width: 315px;
	// }
}

@media screen and (max-width: 615px) {
	.card_section {
		width: 100%;
	}
}

@media screen and (max-width: 800px) {
	.link {
		// width: 235px;
	}
	.wrapper {
		margin-top: -20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.card_section {
		// margin: 0 auto;
	}
	// .card_wrapper {
	// 	width: 235px;
	// 	height: 158px;
	// }

	.card {
		width: 235px;
		height: 158px;
	}

	.service {
		bottom: 15px;
		left: 19px;
		padding: 7px 13px;
	}
	.form {
		display: block;
		margin-top: 20px;
		width: 100%;
	}
}

@media screen and (max-width: 576px) {
	.card_section {
		justify-content: center;
		width: 80%;
	}

	.link {
		width: 100%;
		display: inline;
		margin: 15px 0;
	}
	.card_wrapper {
		width: 100%;
		height: 81%;
		// height: 230px;
		display: block;
		margin: 0 auto 17px auto;
	}

	.link:nth-child(5) {
		margin-left: 0;
	}

	// .card {
	//   width: 100%;
	//   height: 230px;
	//   margin: 0 auto;
	// }

	.name {
		margin-top: 15px;
	}
}

@media screen and (max-width: 415px) {
	.card_section {
		width: 90%;
		gap: 0;
	}
	.card_wrapper {
		margin: 0 auto 6px auto;
		img {
			height: auto;
		}

		.service {
			bottom: 12px;
		}

		.card_section {
			width: 100%;
		}
	}

	.link:nth-child(3) {
		.card_wrapper {
			.service {
				bottom: 26px;
			}
		}
	}
}
