
.image {
  position: relative;
  margin-left: -50px;
  margin-top: -15px;
  margin-right: -50px;
  border-radius: 50px 0 0 0;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 562px;
    object-fit: cover;
  }
  h1 {
    position: absolute;
    bottom: 0;
    left: 70px;
    color: rgb(255, 255, 255);
  font-size: 60px;
    // background-color: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    padding: 5px 10px;
    display: inline-block;
  }
}

.description {
  margin-top: 55px;
  position: relative;
  height: calc(100% + 100px);
  //   overflow: hidden;

  article {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7rem;
    max-width: 1000px;
  }
}

.subheader {
  font-size: 40px;
  margin-top: 40px;
}

.topWrapper {
    margin-top: 60px;
  display: flex;
  gap: 25px;
  align-items: flex-end;
}

.decisionImg {
  margin-top: 40px;
  display: flex;
    position: relative;
  margin-left: -50px;
  margin-right: -50px;
  overflow: hidden;

  img {
    width: 50%;
  }
}

.resultSubheader {
  margin-top: 75px;
}

.resText {
      margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7rem;
    max-width: 1000px;
}

.resBg {
  background-color: black;
  height: 392px;
      position: relative;
  margin-left: -50px;
  margin-right: -50px;
  overflow: hidden;
  margin-top: 75px;

  img {
  height: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  }
}

.resBgBottom {
  display: block;
  margin-top: 50px;
  width: 100%;

  &__top {
    display: flex;
    justify-content: space-between;

    img {
      width: 48%;
    }
  }
    &__bot {
    margin: 50px auto 0 auto;
        width: 100%;
  }
}


.form {
  display: flex;
  margin-top: 50px;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .form {
    display: flex;
    flex-direction: column;
    // top: 350px;
  }

  .contacts {
    margin-bottom: 30px;
  }
  
}

@media screen and (max-width: 1000px) {
  .image {
    h1 {
      width: 89%;
    }
  }

    .topWrapper {
    margin-top: 30px;
    display: flex;
    gap: 25px;
  }
}

@media screen and (max-width: 800px) {
  .image {
    h1 {
      width: 82%;
      font-size: 56px;
    }
  }

      .topWrapper {
        width: 100%;
    img {
    width: 24%;
    }
  }
  
  .description {
    article {
      font-size: 22px;
    }
  }

  .resBg {
    height: 303px;
  }
}



@media screen and (max-width: 576px) {
  .image {
    h1 {
      left: 47px;
      width: 79%;
      font-size: 46px;
      bottom: -21px;
    }
  }

    .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      margin-top: 15px;
      font-size: 19px;
    }
    h3 {
      font-size: 38px;
    }

        .resBg {
    height: 171px;
  }
  }

  .resBgBottom {
  &__top {
    flex-direction: column;
          gap: 50px;

    img {
      width: 100%;
    }
  }
}

    .topWrapper {
    flex-direction: column;
    align-items: center;
    img {
      width: 50%;
    }
    img:nth-child(2n + 2) {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 415px) {
  .image {
    h1 {
      width: 76%;
      font-size: 34px;
      bottom: 0px;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      font-size: 18px;
    }
    h3 {
      font-size: 34px;
    }
  }



    .topWrapper {
    gap: 25px;

    img {
      height: 100%;
    }
  }
}