.topSection {
  position: relative;
}

.image {
  position: relative;
  margin-left: -50px;
  margin-top: -15px;
  margin-right: -50px;
  border-radius: 50px 0 0 0;
  overflow: hidden;

  img {
    width: 100%;
    max-height: 562px;
    object-fit: cover;
      object-position: left center;
  }
}

  .header {
    bottom: 0;
    left: 70px;
    font-size: 65px;
    border-radius: 25px;
    display: inline-block;
    margin: 30px auto 0 auto;
  }

.description {
  margin-top: 55px;
  position: relative;
  height: calc(100% + 100px);
  //   overflow: hidden;
  h3,
  h2 {
    font-size: 40px;
    margin-top: 40px;
    font-weight: 700;
  }
}

.taskText {
margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7rem;
    max-width: 1000px;
}

.processExample {
  display: flex;
  align-items: flex-end;
  gap: 23px;
  margin-bottom: 75px;

      p {
      max-width: 440px;
      font-size: 20px;
font-weight: 500;
    }
}


.bottomImage {
  margin-top: 30px;
  width: 70%;
  max-width: 1110px;
}

.form {
  display: flex;
  margin-top: 50px;
}

// @media screen and (min-width: 2200px) {
//   .images_left {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     .leftTop,
//     .leftBottom {
//       width: 47%;
//       align-items: center;
//     }
//   }
// }

@media screen and (max-width: 1300px) {
  .processExample {
    img {
      width: 65%;
    }
  }
  .form {
    display: block;
    top: 350px;
  }

  .contacts {
    margin-bottom: 30px;
  }
}


@media screen and (max-width: 800px) {
    .header {
      font-size: 49px;
      width: 87%;
    }

  .description {
    article {
      font-size: 22px;
    }

    h2,
    h3 {
      font-weight: 700;
    }
  }

  .bottomImage {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
    .header {
      font-size: 34px;
      left: 40px;
    }

        .processExample {
flex-direction: column;
align-items: start;
          img {
            width: 100%;
          }

      p {
      max-width: 440px;
      font-size: 20px;
font-weight: 500;
    }
}

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      margin-top: 15px;
      font-size: 19px;
    }
    h3,
    h2 {
      font-size: 35px;
    }
  }
}

@media screen and (max-width: 415px) {
    .header {
    width: 100%;
    font-size: 29px;
    margin: 17px auto 0 auto;
    }

    .processExample {
      margin-bottom: 35px;
    }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      font-size: 18px;
    }
    h3 {
      font-size: 30px;
    }
  }
}
