.wrapper {
	// max-width: 1400px;
	overflow: hidden;
}

// это стили для блика
.title {
	position: relative;
	margin-top: 30px;
	font-size: 55px;
	font-weight: 800;
	background-size: contain;
	line-height: 5rem;
	filter: drop-shadow(0 5px 3px #bfccd8);
	-webkit-background-clip: text;
	// &::before {
	//   content: attr(data-heading);
	//   position: absolute;
	//   left: 0;
	//   top: 0;
	//   width: 100%;
	//   background: linear-gradient(
	//     45deg,
	//     rgba(255, 255, 255, 0) 45%,
	//     rgba(255, 255, 255, 0.6) 50%,
	//     rgba(255, 255, 255, 0) 55%,
	//     rgba(255, 255, 255, 0) 100%
	//   );
	//   -webkit-background-clip: text;
	//   color: transparent;
	//   mix-blend-mode: screen;
	//   animation: shine 3s infinite linear;
	//   background-size: 200%;
	//   // text-shadow: 2px 2px 10px rgba(#000, 0.2), -2px 2px 10px rgba(#000, 0.2), -2px -2px 10px rgba(#000, 0.2);
	// }
}

@keyframes shine {
	0% {
		background-position: -100%;
	}
	100% {
		background-position: 100%;
	}
}

.service {
	display: flex;
	justify-content: space-between;
	margin: 30px 0;

	& span {
		display: flex;
		align-items: center;
		font-size: 20px;
		font-weight: 600;
		padding: 5px 15px;
		border: 2px solid black;
		background-repeat: 20px;
		border-radius: 20px;
	}
}

.dots {
	&_wrapper {
		display: flex;
		align-items: center;
		gap: 30px;

		a {
			width: 50px;
			height: 50px;
			border-radius: 50px;
			border: 2px solid black;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 80%;
				height: 80%;
			}
		}
	}
}

.info {
	display: flex;
	margin-top: 30px;
	justify-content: space-between;
	gap: 30px;

	&_left {
		max-width: 750px;

		& h4 {
			font-size: 30px;
			font-weight: 700;
			max-width: 750px;
		}

		& p {
			font-size: 20px;
			margin-top: 35px;
			line-height: 1.6rem;
		}
	}

	&_right {
		margin-right: 100px;
		min-width: 310px;

		& div {
			display: flex;
			align-items: center;
			width: 104%;

			& a {
				font-size: 25px;
				font-weight: 700;
				color: #c8d9e9;
				border-bottom: 3px solid #c8d9e9;
			}
			& img {
				margin-left: 7px;
				width: 20px;
				height: 20px;
				animation: leftRight 2s ease infinite;
			}
		}
		& p {
			font-size: 18px;
			max-width: 280px;
			margin-top: 8px;
		}
	}
}

.cards {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	// max-width: 800px;
	max-width: 100%;
	margin-top: 30px;

	// добавил новое
	gap: 30px;
}

// стили картинок
.painting,
.marker,
.book,
.paints,
.ph_emoji,
.ph_flash,
.web_icon,
.keyboard,
.icons3d,
.vis1 {
	position: absolute;
}
.painting {
	top: -122px;
	right: -5px;
	width: 200px;
	height: 200px;
	z-index: 10;
}
.marker {
	top: 107px;
	right: 55px;
	width: 200px;
	height: 200px;
}
.book {
	bottom: -179px;
	right: 225px;
	width: 150px;
	height: 150px;
}
.paints {
	bottom: -188px;
	right: 455px;
	width: 100px;
	height: 100px;
}
.ph_emoji {
	width: 200px;
	height: 200px;
	top: 300px;
	right: -20px;
}
.ph_flash {
	width: 200px;
	height: 200px;
	top: 1000px;
	right: 100px;
}
.web_icon {
	width: 100px;
	height: 100px;
	top: -46px;
	right: 49px;
}
.keyboard {
	width: 100px;
	height: 100px;
	bottom: -70px;
	right: 300px;
}
.icons3d {
	width: 100px;
	height: 100px;
	top: -33px;
	right: 32px;
}
.vis1 {
	width: 200px;
	height: 200px;
	top: -18px;
	left: 450px;
}

.card:nth-child(1) {
	.web_icon {
		right: -23px;
	}
}

.card {
	position: relative;
	margin-top: 15px;
	// max-width: 340px;

	max-width: 40%;

	margin-right: 30px;

	&_name {
		font-size: 20px;
		font-weight: 700;
		line-height: 2.5rem;
		margin-bottom: 10px;
	}

	&_text {
		margin-top: 10px;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.4rem;
		max-width: 340px;
	}

	&_list {
		margin-top: 15px;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.4rem;
	}
}

.article {
	margin-top: 40px;
	max-width: 700px;
	font-size: 19px;
	font-weight: 600;
	line-height: 1.4rem;
}

.works {
	position: relative;
	padding: 50px 0;
	&_subtitle,
	&_subtitleStatic {
		font-size: 40px;
		font-weight: 700;
		margin-bottom: 45px;
	}

	&_onlyCarousel {
		display: flex;
		gap: 30px;
		flex-wrap: wrap;
		margin-bottom: 50px;
		justify-content: center;
	}

	&_onlyCarouselParfume {
		display: flex;
		gap: 30px;
		margin-bottom: 50px;
		justify-content: center;
		flex-wrap: wrap;
	}

	&_onlyCarouselParfume {
		img:nth-child(1) {
			width: 41%;
			object-fit: cover;
			border-radius: 32px;
		}

		img:nth-child(2) {
			width: 55%;
			object-fit: cover;
			border-radius: 32px;
		}
	}

	&_cardOnly {
		width: 45%;
		height: 30%;
		overflow: hidden;
		border-radius: 25px;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: all 0.8s;

			&:hover {
				transform: scale(1.2);
				cursor: pointer;
			}
		}
		div {
			position: absolute;
			right: 17px;
			bottom: 19px;
			font-size: 24px;
			color: #fff;
			border-radius: 22px;
			padding: 8px 18px;
			background-color: #272727;
		}
	}

	//
	&_smallWrapper {
		position: relative;
		width: 100%;
		height: 623px;
		overflow: hidden;
	}

	// &_smallWrapper {
	//   position: relative;
	//   width: 100%;
	//   height: 350px;
	//   overflow: hidden;
	// }

	//
	&_carousel {
		// display: block;
		margin-bottom: 50px;
		//
		display: flex;
		position: absolute;
		left: 0;
		width: 200%;
		// height: 50px;
	}

	&_carousel3d {
		height: 80%;
		width: 218%;
	}

	//
	&_topCarousel,
	&_bottomCarousel {
		margin-top: 20px;
		display: flex;
		justify-content: space-around;
		//
		width: 50%;

		//
		height: 100%;
		height: 100%;
	}

	// пропр

	// &_topCarousel {
	//   height: 100%;
	//   height: 100%;
	// }

	&_card {
		position: relative;
		width: 30%;
		height: 330;
		border-radius: 25px;
		overflow: hidden;

		&_img {
			width: 100%;
			width: 98%;
			object-fit: cover;
		}

		&_case {
			position: absolute;
			top: 20px;
			right: 20px;
			color: #fff;
			padding: 8px 18px;
			border: 2px solid #fff;
			border-radius: 20px;
			font-size: 20px;
			letter-spacing: 1px;
		}
	}
}

.works_card3d {
	width: auto;
	height: auto;
	margin: 0 15px;
}

.works {
	&_miniWrapper {
		display: flex;
		position: relative;
	}
}

.list {
	padding: 0;
	margin-bottom: 60px;

	&_item {
		line-height: 2rem;
		display: list-item;
		list-style-position: inside;
		margin: 15px 0;
		font-size: 18px;
		font-weight: 500;

		& span {
			font-weight: 700;
			margin-right: 35px;
			font-size: 18px;
		}
	}
}

.detailList {
	display: flex;
	gap: 30px;

	&_item {
		position: relative;
		font-size: 25px;
		font-weight: 700;
		margin-bottom: 35px;

		hr {
			margin: 15px 0 20px 0;
		}

		article {
			font-weight: 600;
			font-size: 22px;
		}
	}
}

.form {
	display: flex;
	margin-top: 50px;
}

//
@keyframes marquee {
	0% {
		left: 0;
	}
	100% {
		left: -100%; /* тут поменял для анимации в другую сторону */
	}
}

@keyframes marqueeRight {
	0% {
		left: 0;
	}
	100% {
		left: 100%; /* тут поменял для анимации в другую сторону */
	}
}
//
.animate,
.animateRight {
	width: 200%;
	animation: marquee 20s linear infinite;
	top: 50%;
	transform: translate(0, -60%); /* тут поменял для анимации в другую сторону */
}

.animateRight {
	animation: marqueeRight 20s linear infinite;
	transform: translate(-50%, -60%);
}

.animate:hover,
.animateRight:hover {
	animation-play-state: paused;
}

// .bigWrapper {
//   display: none;
// }

@media screen and (min-width: 1600px) {
	// .bigWrapper {
	//   display: flex;
	//   flex-wrap: wrap;
	// }

	// .works_onlyCarousel {
	//   display: flex;
	//   margin-right: 20px;
	// }

	.works_onlyCarouselParfume {
		img:nth-child(1) {
			width: 519px;
			object-fit: cover;
			border-radius: 32px;
		}

		img:nth-child(2) {
			width: 698px;
			object-fit: cover;
			border-radius: 32px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.service {
		& span {
			display: flex;
			align-items: center;
			font-size: 17px;
		}
	}

	.dots_wrapper {
		display: flex;
		align-items: center;
		gap: 30px;

		a {
			width: 40px;
			height: 40px;
		}
	}
}

@media screen and (max-width: 1300px) {
	.works {
		&_subtitle {
			margin-bottom: 15px;
		}

		&_smallWrapper {
			height: 270px;
		}
	}

	.info_right {
		margin-right: 0;
	}

	.ph_emoji {
		width: 150px;
		height: 150px;
		top: 400px;
		right: -20px;
	}
	.ph_flash {
		width: 150px;
		height: 150px;
		top: 1000px;
		right: 100px;
	}

	.form {
		display: block;
		margin-top: 40px;
	}

	.works_card_case {
		font-size: 15px;
		top: 15px;
		right: 14px;
	}

	.animate3d {
		top: 60%;
	}
	.topCarousel3d {
		height: 85%;
	}
}

@media screen and (max-width: 2000px) {
	.works_smallWrapper {
		height: 512px;
	}
}
@media screen and (max-width: 1500px) {
	.works_smallWrapper {
		height: 363px;
	}

	.works_onlyCarousel {
		justify-content: center;

		img {
			width: 45%;
			object-fit: cover;
			border-radius: 62px;
		}

		.onlyImage {
			width: 90%;
		}
	}
}

@media screen and (max-width: 1250px) {
	.works_smallWrapper {
		height: 276px;
	}

	.painting {
		top: -15px;
		right: 29px;
		width: 150px;
		height: 150px;
	}
	.marker {
		top: 108px;
		right: 20px;
		width: 150px;
		height: 150px;
	}
	.book {
		bottom: -179px;
		right: 35px;
		width: 100px;
		height: 100px;
	}
}

@media screen and (max-width: 1130px) {
	.works {
		&_smallWrapper {
			height: 249px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.title {
		margin-top: 0;
		font-size: 45px;
	}

	.info_right {
		div {
			a {
				font-size: 19px;
			}
		}
	}

	.works_onlyCarousel {
		justify-content: center;

		img {
			width: 45%;
			object-fit: cover;
			border-radius: 32px;
		}

		.onlyImage {
			width: 90%;
		}
	}

	.painting {
		top: -15px;
		width: 90px;
		height: 90px;
	}
	.marker {
		top: 128px;
		right: 20px;
		width: 90px;
		height: 90px;
	}
	.book {
		display: none;
	}
	.paints {
		bottom: -170px;
		right: 255px;
		width: 70px;
		height: 70px;
	}
	.ph_flash {
		width: 100px;
		height: 100px;
		top: 1100px;
		right: 20px;
	}
	.icons3d {
		width: 80px;
		height: 80px;
		top: -33px;
		right: -28px;
	}
	.vis1 {
		width: 150px;
		height: 150px;
		top: -18px;
		left: 400px;
	}

	.works {
		&_image {
			left: auto;
			right: 100px;
		}

		&_cardOnly {
			div {
				font-size: 18px;
			}
		}
	}

	.works {
		&_smallWrapper {
			height: 213px;
		}

		//
		&_topCarousel,
		&_bottomCarousel {
			margin-top: 20px;
			display: flex;
			justify-content: space-around;
			//
			width: 50%;
		}

		// &_card3d {
		//   height: 150px;
		// }
	}

	.animate3d {
		top: 50%;
	}

	.topCarousel3d {
		height: 100%;
	}

	.detailList {
		max-width: 65%;
	}
}

@media screen and (max-width: 800px) {
	.wrapper {
		margin: 0 30px;
	}
	.dots_long {
		display: none;
	}
	.info_left {
		h4 {
			font-size: 23px;
		}
	}

	.ph_emoji {
		top: 486px;
	}

	.info {
		flex-direction: column;
	}

	.info_right {
		div {
			a {
				font-size: 15px;
			}
		}

		p {
			font-size: 15px;
		}
	}

	.works_onlyCarouselParfume {
		img:nth-child(1) {
			width: 100%;
			object-fit: cover;
			border-radius: 32px;
		}

		img:nth-child(2) {
			width: 100%;
			object-fit: cover;
			border-radius: 32px;
		}
	}

	.works_onlyCarousel {
		justify-content: center;

		img {
			width: 100%;
		}

		.onlyImage {
			width: 100%;
		}
	}

	.web_icon {
		width: 100px;
		height: 100px;
		top: -46px;
		right: -10px;
	}
	.keyboard {
		width: 80px;
		height: 80px;
		bottom: -30px;
		right: 150px;
	}
	.icons3d {
		width: 80px;
		height: 80px;
		right: -75px;
	}
	.vis1 {
		width: 130px;
		height: 130px;
		top: -18px;
		left: 400px;
	}

	.card:nth-child(1) {
		.web_icon {
			right: -77px;
		}
	}

	.works {
		&_image {
			left: auto;
			right: -12px;
		}
	}

	.works {
		&_subtitle {
			margin-bottom: 15px;
		}
		&_smallWrapper {
			height: 165px;
		}

		&_cardOnly,
		&_cardOnly:nth-child(2) {
			justify-content: center;
		}

		&_imgOnly {
			width: 100%;
		}

		&_card_case {
			// top: 31px;
			// right: 27px;
			top: 9px;
			right: 11px;
			font-size: 14px;
			padding: 5px 12px;
		}
	}

	.form {
		margin-top: 0;
	}
}

@media screen and (max-width: 576px) {
	.wrapper {
		// margin: 0 20px;
		// width: 100%;

		// hr {
		//   margin-right: 50px;
		// }
	}

	.title {
		font-size: 35px;
		line-height: 3rem;
	}

	.info {
		flex-direction: column;

		&_left {
			h4 {
				margin-top: 0;
			}

			p {
				margin-top: 15px;
				z-index: 3;
				position: relative;
			}
		}

		&_right {
			div {
				a {
					font-size: 20px;
				}
			}
		}
	}

	.paints {
		bottom: -205px;
		right: 75px;
	}

	.painting {
		top: -15px;
		width: 70px;
		height: 70px;
	}
	.marker {
		top: 168px;
		right: 20px;
		width: 60px;
		height: 60px;
	}
	.ph_emoji {
		width: 100px;
		height: 100px;
		top: 590px;
		right: -20px;
	}
	.web_icon {
		width: 100px;
		height: 100px;
		top: -46px;
		right: 10px;
	}
	.keyboard {
		width: 80px;
		height: 80px;
		bottom: -30px;
		right: 50px;
	}
	.vis1 {
		width: 100px;
		height: 100px;
		top: -2px;
		left: 308px;
	}

	.smallScreen {
		display: none;
	}

	.detailList {
		max-width: 80%;
	}

	.works {
		&_image {
			margin-left: 10%;
			width: 70px;
			height: 70px;
			animation: updown 2s ease infinite;
		}
	}

	.works {
		&_smallWrapper {
			position: relative;
			width: 100%;
			// height: 350px;
			height: auto;
			overflow: hidden;
			margin-bottom: 5px;
		}

		&_carousel {
			display: block;
			margin-bottom: 50px;
			//
			// display: flex;
			position: static;
			width: 100%;
			margin-bottom: 0;
			// height: 50px;
		}

		&_topCarousel,
		&_bottomCarousel {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			margin-top: 0;

			// margin: 10px auto;
		}

		&_card {
			width: 90%;
			margin: 20px 0px;
			height: auto;
			// position: relative;
			// width: 30%;
			// height: 330;
			// border-radius: 25px;
			// overflow: hidden;
		}
		&_card_case {
			top: 31px;
			right: 27px;
		}
	}

	.works_topCarousel:nth-child(2),
	.works_bottomCarousel:nth-child(2) {
		display: none;
	}

	.animate,
	.animateRight {
		width: 100%;
		animation: marquee 20s linear infinite;
		top: 50%;
		transform: translate(0, 0); /* тут поменял для анимации в другую сторону */
	}

	.cards {
		flex-wrap: nowrap;
		flex-direction: column;
	}

	.card {
		display: block;
		max-width: 80%;
	}

	.article {
		max-width: 90%;
		margin-top: 20px;
	}

	.works_onlyCarouselParfume {
		img:nth-child(1) {
			width: 100%;
			object-fit: cover;
			border-radius: 32px;
		}

		img:nth-child(2) {
			width: 100%;
			object-fit: cover;
			border-radius: 32px;
		}
	}

	// .form {
	//   width: 100%;
	//   margin: 0 auto;
	// }
}

@media screen and (max-width: 415px) {
	.wrapper {
		margin: 0 10px;
	}

	.works_subtitle {
		margin-bottom: 25px;
	}

	.info_right3d {
		margin: 15px 0 0 0;
	}

	.dots_wrapper {
		gap: 15px;
	}

	// картинки
	.ph_emoji {
		width: 100px;
		height: 100px;
		top: 680px;
		right: -20px;
		z-index: 1;
	}
	.ph_flash {
		width: 70px;
		height: 70px;
		top: 1600px;
		right: 20px;
	}
	.icons3d {
		width: 60px;
		height: 60px;
		top: 20px;
		right: -30px;
	}
	.vis1 {
		width: 80px;
		height: 80px;
		top: 47px;
		left: 248px;
	}

	.cards {
		margin-top: 10px;
	}

	.works3d {
		padding: 10px 0 40px 0;
	}

	.card {
		max-width: 90%;
	}

	.detailList {
		max-width: 90%;
	}

	.works_smallWrapper {
		margin: 0 auto;
	}

	.works {
		&_image {
			display: none;
		}
	}

	.animate,
	.animateRight {
		width: 108%;
		transform: translate(
			-14px,
			0
		); /* тут поменял для анимации в другую сторону */
	}

	.list_item {
		span {
			margin-right: 15px;
		}
	}
}

@keyframes leftRight {
	0% {
		transform: translateX(-10%);
	}

	50% {
		transform: translateX(65%);
	}

	100% {
		transform: translateX(-15%);
	}
}
