.wrapper {
  // max-width: 1400px;
  overflow: hidden;
}

// это стили для блика
.title {
  position: relative;
  margin-top: 30px;
  font-size: 55px;
  font-weight: 800;
  background-size: contain;
  line-height: 5rem;
  filter: drop-shadow(0 5px 3px #bfccd8);
  -webkit-background-clip: text;
  &::before {
    content: attr(data-heading);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0) 45%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    color: transparent;
    mix-blend-mode: screen;
    animation: shine 3s infinite linear;
    background-size: 200%;
    // text-shadow: 2px 2px 10px rgba(#000, 0.2), -2px 2px 10px rgba(#000, 0.2), -2px -2px 10px rgba(#000, 0.2);
  }
}

@keyframes shine {
  0% {
    background-position: -100%;
  }
  100% {
    background-position: 100%;
  }
}

.service {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;

  & span {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 15px;
    border: 2px solid black;
    background-repeat: 20px;
    border-radius: 20px;
  }
}

.dots {
  &_wrapper {
    display: flex;
    align-items: center;
    gap: 30px;

    a {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 2px solid black;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 80%;
        height: 80%;
      }
    }
  }

  // &_long {
  //   margin-right: 20px;
  //   width: 150px;
  //   height: 40px;
  //   background-color: black;
  //   border-radius: 20px;
  // }

  // &_short {
  //   margin-right: 20px;
  //   width: 30px;
  //   height: 30px;
  //   background-color: black;
  //   border-radius: 50%;
  // }
}

// .info {
//   display: flex;
//   margin-top: 30px;
//   justify-content: space-between;
//   //   gap: 30px;

//   &_left {
//     //  max-width: 750px;

//     & h4 {
//       font-size: 30px;
//       font-weight: 700;
//       max-width: 750px;
//     }
//   }

//   &_right {
//     position: relative;
//     margin: 0 100px;
//     //  width: 310px;

//     & div {
//       display: flex;
//       align-items: center;
//       width: 108%;

//       & a {
//         font-size: 25px;
//         font-weight: 700;
//         color: #c8d9e9;
//         border-bottom: 3px solid #c8d9e9;
//         width: 100%;
//       }
//     }
//     & p {
//       font-size: 20px;
//       max-width: 280px;
//       margin-top: 8px;
//       font-weight: 600;
//     }
//     img {
//       margin-left: 7px;
//       width: 20px;
//       height: 20px;
//       animation: leftRight 2s ease infinite;
//       position: absolute;
//       top: 7px;
//       right: -30px;
//     }
//   }
// }

.info {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  gap: 30px;

  &_left {
    // max-width: 750px;

    & h4 {
      font-size: 38px;
      font-weight: 700;
      max-width: 750px;
    }

    & p {
      font-size: 20px;
      margin-top: 35px;
      line-height: 1.6rem;
    }
  }

  &_right {
    margin-right: 100px;
    min-width: 300px;

    & div {
      display: flex;
      align-items: center;
      width: 104%;

      & a {
        font-size: 25px;
        font-weight: 700;
        color: #c8d9e9;
        border-bottom: 3px solid #c8d9e9;
      }
      & img {
        margin-left: 7px;
        width: 20px;
        height: 20px;
        animation: leftRight 2s ease infinite;
      }
    }
    & p {
      font-size: 18px;
      max-width: 280px;
      margin-top: 8px;
    }
  }
}

// .cards {
//   position: relative;
//   display: flex;
//   flex-wrap: wrap;
//   // max-width: 800px;
//   max-width: 100%;
//   margin-top: 30px;
// }

// стили картинок

.article {
  margin-top: 40px;
  max-width: 700px;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.4rem;
}

.works {
  position: relative;
  padding: 50px 0;

  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    width: 73%;
    margin: 25px 0;
  }
  &_subtitle {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 45px;
  }

  &_onlyCarousel {
    display: block;
    max-width: 1300px;
    margin-bottom: 50px;
  }

  &_cardOnly {
    display: flex;
  }
  &_cardOnly:nth-child(2) {
    justify-content: flex-end;
    margin: 50px 0;
  }

  //
  &_smallWrapper {
    position: relative;
    width: 100%;
    height: 354px;
    overflow: hidden;
  }

  //
  &_carousel {
    // display: block;
    margin-bottom: 50px;
    //
    display: flex;
    position: absolute;
    left: 0;
    width: 200%;
    // height: 50px;
  }

  //
  &_topCarousel,
  &_bottomCarousel {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    //
    width: 50%;

    //
    height: 100%;
    height: 100%;
  }

  // пропр

  // &_topCarousel {
  //   height: 100%;
  //   height: 100%;
  // }

  &_card {
    position: relative;
    width: 30%;
    height: 330;
    border-radius: 25px;
    overflow: hidden;

    &_img {
      width: 95%;
      heigh: 300px;
      object-fit: cover;
    }

    &_case,
    &_case1 {
      position: absolute;
      top: 22px;
      right: 41px;
      color: #fff;
      padding: 8px 18px;
      border: 2px solid #fff;
      // background-color: #272727;
      border-radius: 20px;
      font-size: 20px;
      letter-spacing: 1px;
    }
  }
}

.works_card:nth-child(3) {
  .works_card_case {
    background-color: #272727;
    border: 2px solid #272727;
  }
}

.works_card:nth-child(2n + 1) {
  .works_card_case1 {
    background-color: #272727;
    border: 2px solid #272727;
  }
}

.workSubtitle {
  font-weight: 700;
  font-size: 48px;
  line-height: 55px;
  margin-top: 25px;
}

.typesWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 25px;
}

.types {
  display: flex;
  align-items: center;
  width: 45%;

  h5 {
    font-size: 21px;
    margin-bottom: 20px;
  }

  p {
    max-width: 400px;
    font-size: 17px;
    line-height: 1.5rem;
  }
}

.works {
  &_miniWrapper {
    display: flex;
    position: relative;
  }

  &_image {
    position: absolute;
    top: 0;
    left: 50px;
    margin-left: 30%;
    width: 70px;
    height: 70px;
    animation: updown 2s ease infinite;
  }
}
// анимация для стрелочки

@keyframes updown {
  0% {
    transform: rotate(3.142rad) translateY(-10%);
  }

  50% {
    transform: rotate(3.142rad) translateY(25%);
  }

  100% {
    transform: rotate(3.142rad) translateY(-10%);
  }
}

.list {
  padding: 0;
  margin-bottom: 60px;

  &_item {
    display: list-item;
    list-style-position: inside;
    margin: 15px 0;
    font-size: 18px;
    font-weight: 500;

    & span {
      font-weight: 700;
      margin-right: 35px;
      font-size: 18px;
    }
  }
}

.detailList {
  // max-width: 45%;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  margin-top: 41px;

  &_item {
    position: relative;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 35px;
    // width: 600px;
    width: 45%;

    hr {
      margin: 15px 0 20px 0;
    }

    article {
      font-weight: 600;
      font-size: 22px;
    }
  }
}

.form {
  display: flex;
}

//
@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%; /* тут поменял для анимации в другую сторону */
  }
}

@keyframes marqueeRight {
  0% {
    left: 0;
  }
  100% {
    left: 100%; /* тут поменял для анимации в другую сторону */
  }
}
//
.animate,
.animateRight {
  width: 200%;
  animation: marquee 20s linear infinite;
  top: 62%;
  transform: translate(0, -60%); /* тут поменял для анимации в другую сторону */
}

.animateRight {
  animation: marqueeRight 20s linear infinite;
  transform: translate(-50%, -60%);
}

.animate:hover,
.animateRight:hover {
  animation-play-state: paused;
}

@media screen and (min-width: 2600px) {
  .works {
    &_smallWrapper {
      // position: relative;
      width: 100%;
      height: auto;
      margin-bottom: 5px;
    }

    &_carousel {
      display: block;
      margin-bottom: 50px;
      position: static;
      width: 100%;
      margin-bottom: 0;
    }

    &_topCarousel,
    &_bottomCarousel {
      display: flex;
      align-items: center;
      width: 69%;
      margin-top: 0;
      gap: 30px;

      // margin: 10px auto;
    }

    &_card {
      width: 92%;
      margin-top: 30px;

      &_img {
        width: 100%;
      }

      &_card_case {
        top: 15px;
        right: 42px;
      }
    }

    .works_topCarousel:nth-child(2),
    .works_bottomCarousel:nth-child(2) {
      display: none;
    }

    .animate,
    .animateRight {
      top: 50%;
      transform: translate(0, 0); /* тут поменял для анимации в другую сторону */
    }
    .article {
      max-width: 90%;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 1550px) {
  .works_smallWrapper {
    height: 211px;
  }

  // .works_card_case {
  //   font-size: 11px;
  //   top: 13px;
  //   right: 23px;
  // }
}

@media screen and (max-width: 1300px) {
  .works {
    &_subtitle {
      margin-bottom: 15px;
    }
    // &_smallWrapper {
    //   height: 270px;
    // }

    &_card {
      // width: 70%;
    }

    &_topCarousel {
    }

    h2 {
      width: 88%;
    }
  }

  .info_right {
    margin-right: 30px;
  }

  .ph_emoji {
    width: 150px;
    height: 150px;
    top: 400px;
    right: -20px;
  }
  .ph_flash {
    width: 150px;
    height: 150px;
    top: 1000px;
    right: 100px;
  }

  .form {
    display: block;
    margin-top: 40px;
  }

  .works_card_case,
  .works_card_case1 {
    font-size: 15px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 1250px) {
  .painting {
    top: -15px;
    right: 29px;
    width: 150px;
    height: 150px;
  }
  .marker {
    top: 108px;
    right: 20px;
    width: 150px;
    height: 150px;
  }
  .book {
    bottom: -179px;
    right: 35px;
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1130px) {
  .works {
    &_smallWrapper {
      height: 147px;
    }
  }

  .works_card_case,
  .works_card_case1 {
    top: 9px;
    right: 20px;
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .title {
    margin-top: 0;
    font-size: 45px;
  }

  .service {
    & span {
      display: flex;
      align-items: center;
      font-size: 17px;
    }
  }

  .dots_wrapper {
    display: flex;
    align-items: center;
    gap: 30px;

    a {
      width: 40px;
      height: 40px;
    }
  }

  .info_right {
    div {
      a {
        font-size: 19px;
      }
    }
  }

  .works_card_case,
  .works_card_case1 {
    font-size: 9px;
  }

  .works {
    &_image {
      left: auto;
      right: 100px;
    }

    h2 {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 25px;
    }
  }

  .works {
    &_topCarousel,
    &_bottomCarousel {
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      //
      width: 50%;
    }
  }

  .types {
    width: 85%;
  }
  .detailList {
    // max-width: 65%;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    margin: 0 30px;
  }
  .dots_long {
    display: none;
  }

  .info {
    flex-direction: column;
  }

  .info_left {
    h4 {
      font-size: 32px;
    }
  }

  .info_right {
    div {
      a {
        font-size: 15px;
      }
    }

    p {
      font-size: 15px;
    }
  }

  .web_icon {
    width: 100px;
    height: 100px;
    top: -46px;
    right: -10px;
  }
  .keyboard {
    width: 80px;
    height: 80px;
    bottom: -30px;
    right: 150px;
  }

  .vis1 {
    width: 130px;
    height: 130px;
    top: -18px;
    left: 400px;
  }

  .detailList {
    &_item {
      width: 80%;
    }
  }

  .works {
    &_image {
      left: auto;
      right: -12px;
    }
  }

  .works {
    &_subtitle {
      margin-bottom: 15px;
    }
    &_smallWrapper {
      height: 121px;
    }

    &_cardOnly,
    &_cardOnly:nth-child(2) {
      justify-content: center;
    }

    &_imgOnly {
      width: 100%;
    }

    &_card_case,
    &_card_case1 {
      // top: 31px;
      // right: 27px;
      // top: 15px;
      // right: 21px;
      // font-size: 21px;
    }
  }

  .types {
    width: 100%;
  }

  .form {
    margin-top: 0;
  }
}

@media screen and (max-width: 576px) {
  .title {
    font-size: 35px;
  }

  .info {
    &_left {
      h4 {
        font-size: 28px;

        margin-top: 0;
      }

      p {
        margin-top: 15px;
      }
    }

    &_right {
      // margin-top: 25px;
      div {
        a {
          font-size: 20px;
        }
      }
    }
  }

  //   .carouselBigWrapper {
  //     width: 100%;
  //   }

  .detailList {
    &_item {
      width: 100%;
    }
  }

  .works {
    h2 {
      width: 100%;
      line-height: 37px;
    }
  }
  .works {
    &_smallWrapper {
      position: relative;
      width: 100%;
      // height: 350px;
      height: auto;
      overflow: hidden;
      margin-bottom: 5px;
    }

    &_carousel {
      display: block;
      margin-bottom: 50px;
      //
      // display: flex;
      position: static;
      width: 100%;
      margin-bottom: 0;
      // height: 50px;
    }

    &_topCarousel,
    &_bottomCarousel {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 0;

      // margin: 10px auto;
    }

    &_card {
      width: 92%;
      margin-top: 30px;

      &_img {
        width: 100%;
      }

      &_card_case {
        top: 15px;
        right: 42px;
      }
    }

    .works_topCarousel:nth-child(2),
    .works_bottomCarousel:nth-child(2) {
      display: none;
    }

    .animate,
    .animateRight {
      width: 100%;
      animation: marquee 20s linear infinite;
      top: 50%;
      transform: translate(0, 0); /* тут поменял для анимации в другую сторону */
    }
    .article {
      max-width: 90%;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 415px) {
    .info {
      &_left {
        h4 {
          font-size: 28px;
        }
      }
    }
    .wrapper {
      margin: 0 10px;
    }

    .dots_wrapper {
      gap: 15px;
    }

    .workSubtitle {
      font-size: 37px;
    }

    .info_right {
      display: block;
      margin: 0 auto;
    }

    // картинки
    .ph_emoji {
      width: 100px;
      height: 100px;
      top: 680px;
      right: -20px;
    }
    .ph_flash {
      width: 70px;
      height: 70px;
      top: 1600px;
      right: 20px;
    }

    .vis1 {
      width: 80px;
      height: 80px;
      top: 47px;
      left: 248px;
    }

    //   .cards {
    //     margin-top: 10px;
    //   }

    .types {
      img {
        width: 100px;
        height: 100px;
      }
    }

    .typesWrapper {
      gap: 30px;
    }

    .types {
      align-items: start;
    }

    .card {
      max-width: 90%;
    }

    .detailList {
      max-width: 90%;

      &_item {
        font-size: 23px;

        article {
          font-size: 20px;
        }
      }
    }

    .works_smallWrapper {
      margin: 0 auto;
    }

    .works {
      &_image {
        display: none;
      }

      h2 {
        font-size: 25px;
      }
    }
  }

  .animate,
  .animateRight {
    width: 108%;
    transform: translate(-14px, 0); /* тут поменял для анимации в другую сторону */
  }

  .list_item {
    span {
      margin-right: 15px;
    }
  }
}

@keyframes leftRight {
  0% {
    transform: translateX(-10%);
  }

  50% {
    transform: translateX(65%);
  }

  100% {
    transform: translateX(-15%);
  }
}
