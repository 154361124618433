.iconsWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-top: 20px;
  gap: 10px;
}

.icon {
  width: 50px;
  height: 50px;

  img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .iconsWrapper {
    display: none;
  }
}
