.imgCarousel {
	width: 300px;
}
.slickSlide {
	padding: 0 10px;
	transform: translate3d(0, 0, 0);
	backface-visibility: hidden;
	perspective: 1000;
}

.wrapper {
	margin-bottom: 45px;
	// height: 250px;
}

// .slide {
// 	height: 250px;
// }

.imgWrapper {
	padding: 0;
	// height: 100%;

	img {
		height: 21vw;
	}
}

.staticWrapper {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.imgStaticWrapper {
	img {
		width: 100%;
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 1536px) {
	.imgWrapper {
		// height: 290px;

		img {
			height: 280px;
		}
	}
}

@media screen and (max-width: 1400px) {
	.imgWrapper {
		img {
			height: 20vw;
		}
	}
}

@media screen and (max-width: 1200px) {
	// .imgWrapper {
	// 	img {
	// 		height: 160px;
	// 	}
	// }
}

@media screen and (max-width: 800px) {
	// .imgWrapper {
	// 	img {
	// 		height: 130px;
	// 	}
	// }
}

@media screen and (max-width: 620px) {
	.imgWrapper {
		img {
			height: 130px;
		}
	}
}

// .carouselWrapper {
//   overflow: hidden;
//   position: relative;
//   width: 100%;
//   height: 300px; /* Укажите желаемую высоту карусели */
// }

// .carousel {
//   display: flex;
//   position: absolute;
//   width: 400%; /* Увеличиваем ширину, чтобы вместить все фотографии */
//   animation: scroll 10s linear infinite;
// }

// .item {
//   flex: 0 0 25%; /* 25% ширины для каждой фотографии */
//   box-sizing: border-box; /* Учитываем отступы внутри размера элемента */
// }

// @keyframes scroll {
//   0% {
//     transform: translateX(0%);
//   }
//   100% {
//     transform: translateX(-75%); /* Перемещаем карусель влево на 75% ширины контейнера */
//   }
// }

// .carouselWrapper {
//   overflow: hidden;
//   position: relative;
//   width: 100%;
//   height: 300px; /* Укажите желаемую высоту карусели */
// }

// .carousel {
//   display: flex;
//   position: absolute;
//   width: 150%; /* Увеличиваем ширину, чтобы вместить все фотографии */
//   animation: scroll 10s linear infinite;
// }

// .item {
//   flex: 0 0 25%; /* 25% ширины для каждой фотографии */
//   box-sizing: border-box; /* Учитываем отступы внутри размера элемента */
//   margin-right: 10px; /* Расстояние между фотографиями */
// }

// @keyframes scroll {
//   0% {
//     transform: translateX(0%);
//   }
//   100% {
//     transform: translateX(-75%); /* Перемещаем карусель влево на 75% ширины контейнера */
//   }
// }

// .wrapper {
//   display: flex;
// }
