.topSection {
	position: relative;
}

.image {
	position: relative;
	margin-left: -50px;
	margin-top: -15px;
	margin-right: -50px;
	border-radius: 50px 0 0 0;
	overflow: hidden;
	img {
		width: 100%;
		max-height: 562px;
		object-fit: cover;
	}
	h1 {
		position: absolute;
		bottom: 0;
		left: 70px;
		color: black;
		font-size: 65px;
		// background-color: rgba(255, 255, 255, 0.6);
		border-radius: 25px;
		padding: 5px 10px;
		display: inline-block;
	}
}

.description {
	.subheader {
		font-size: 45px;
		line-height: 3rem;
		font-weight: 700;
		margin: 30px 0 20px 0;
	}

	p {
		font-size: 22px;
		font-weight: 400;
		width: 70%;
		line-height: 1.9rem;
	}

	.wrapper3d {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;

		margin-top: 30px;

		img {
			width: 418px;
			height: 233px;
			margin-bottom: 25px;
		}
	}

	.videoWrapper {
		background: url(../assets/loader_2.gif) center center no-repeat;
		width: 100%;
		height: 600px;
		video {
			margin-top: 25px;
			width: 60%;
			// height: 400px;
			// flex: 1.5;
			// height: auto;
			// box-shadow: 0px 10px 15px 4px rgba(0, 0, 0, 0.4);
		}
	}
}

.form {
	display: flex;
	margin-top: 50px;
}

@media screen and (max-width: 1930px) {
	.wrapper3d {
		justify-content: space-between;
		justify-content: center;
	}
}

@media screen and (max-width: 1500px) {
	.description {
		.wrapper3d {
			img {
				width: 30%;
				height: auto;
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.form {
		display: block;
		top: 350px;
	}

	.contacts {
		margin-bottom: 30px;
	}
}

@media screen and (max-width: 1000px) {
	.image {
		h1 {
			font-size: 48px;
		}
	}

	.description {
		p {
			line-height: 1.7rem;
		}
		.videoWrapper {
			height: 400px;
			video {
				width: 80%;
			}
		}

		.wrapper3d {
			img {
				width: 45%;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.image {
		h1 {
			font-size: 45px;
			width: 80%;
		}
	}

	.description {
		.subheader {
			font-size: 40px;
		}

		p {
			font-size: 21px;
			width: 90%;
		}

		.videoWrapper {
			height: 300px;
			flex-direction: column;
			// align-items: center;

			video {
				//   flex: 1;
				width: 80%;
				margin: 25px auto;
			}
		}

		article {
			font-size: 22px;
		}

		.wrapper3d {
			img {
				width: 418px;
				height: 233px;
				margin-bottom: 25px;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.image {
		img {
			opacity: 0.6;
		}
		h1 {
			font-size: 33px;
			width: 70%;
		}
	}

	.description {
		.subheader {
			font-size: 37px;
			margin: 26px 0 6px 0;
		}

		p {
			width: 100%;
		}

		.wrapper3d {
			img {
				width: 100%;
				height: auto;
			}
		}

		.videoWrapper {
			background-size: 30% auto;
			height: 250px;
			video {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.image {
		h1 {
			font-size: 29px;
			left: 48px;
			width: 79%;
		}
	}

	.description {
		.subheader {
			font-size: 33px;
		}

		p {
			font-size: 20px;
		}

		.imgWrapper {
			img {
				width: 100%;
			}
		}

		.videoWrapper {
			height: 200px;
		}
	}
}
