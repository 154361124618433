.topSection {
  position: relative;
}

.image {
  position: relative;
  margin-left: -50px;
  margin-top: -15px;
  margin-right: -50px;
  border-radius: 50px 0 0 0;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 562px;
    object-fit: cover;
  }
  h1 {
    position: absolute;
    bottom: 0;
    left: 70px;
    color: #fff;
    font-size: 80px;
  }
}

.description {
  margin-top: 55px;
  position: relative;
  height: calc(100% + 100px);
  //   overflow: hidden;
  h3 {
    font-size: 40px;
  }

  article {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7rem;
    max-width: 1000px;
  }
}

.taskImg_wrapper {
  margin-top: 70px;
  margin-left: -50px;
  margin-right: -50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 2050px;
  }
}

.form {
  display: flex;
  margin-top: 50px;
}

@media screen and (max-width: 1300px) {
  .form {
    display: block;
    top: 350px;
  }

  .images {
    &_left {
      gap: 35px;
    }

    &_right {
      gap: 15px;
    }
  }

  .contacts {
    margin-bottom: 30px;
  }

  // .article {
  //   h1 {
  //     font-size: 44px;
  //   }
  // }
}

@media screen and (max-width: 800px) {
  .description {
    article {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 576px) {
  .image {
    h1 {
      font-size: 58px;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      margin-top: 15px;
      font-size: 19px;
    }
    h3 {
      font-size: 38px;
    }
  }
}

@media screen and (max-width: 415px) {
  .image {
    h1 {
      font-size: 50px;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      font-size: 18px;
    }
    h3 {
      font-size: 34px;
    }
  }
}
