// .popupWrapper {
//   position: relative;
// }

// .casesButton {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   //   text-align: center;
//   width: 206px;
//   height: 50px;
//   font-size: 20px;
//   font-weight: 600;
//   background: transparent;
//   border-radius: 15px;
//   border: 2px solid black;
//   margin-top: 50px;

//   img {
//     margin-left: 10px;
//   }

//   &:hover {
//     cursor: pointer;
//   }
// }

// .popup {
//   position: absolute;
//   left: 0px;
//   top: 51px;
//   background: #ffffff;
//   box-shadow: 0px 5px 26px rgb(0 0 0 / 17%);
//   border-radius: 8px;
//   padding: 10px 0;
//   z-index: 5;
//   opacity: 1;
//   transition: all 0.4s;
//   height: 230px;

//   li {
//     font-size: 24px;
//     font-weight: 500;
//     line-height: 2.2rem;
//     padding: 0 17px;

//     &:hover {
//       background-color: #c8d9e9;
//       color: black;
//       cursor: pointer;
//     }
//   }
// }

// .activeType {
//   background-color: #272727;
//   color: #fff;
// }

// .item1,
// .item2,
// .item3,
// .item4,
// .item5,
// .item6,
// .item7,
// .item8,
// .item9,
// .item10,
// .item11,
// .item12,
// .item13 {
//   overflow: hidden;
//   border-radius: 25px;
// }

// // .item1 {
// //   grid-area: item1;
// // }
// // .item2 {
// //   grid-area: item2;
// // }
// // .item3 {
// //   grid-area: item3;
// // }
// // .item4 {
// //   grid-area: item4;
// // }
// // .item5 {
// //   grid-area: item5;
// // }
// // .item6 {
// //   grid-area: item6;
// // }
// // .item7 {
// //   grid-area: item7;
// // }
// // .item8 {
// //   grid-area: item8;
// // }
// // .item9 {
// //   grid-area: item9;
// // }

// .cardContainer {
//   margin-top: 40px;
//   display: grid;
//   gap: 35px;
//   //   grid-template-areas: 'item1 item2 item3' 'item4 item5 item6' 'item7 item9 item9' 'item8 item9 item9';
//   padding: 10px;

//   grid-template-columns: repeat(3, 1fr);

//   a {
//     position: relative;
//     // width: 430px;
//     // height: 292px;
//   }
//   img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     transition: all 0.8s;

//     &:hover {
//       transform: scale(1.2);
//     }
//   }

//   span {
//     position: absolute;
//     right: 17px;
//     bottom: 19px;
//     font-size: 24px;
//     color: #fff;
//     border-radius: 22px;
//     padding: 8px 18px;
//     background-color: #272727;
//   }
// }

// .form {
//   display: flex;
//   margin-top: 50px;
// }

//
//
//

// .casesWrapper {
//   margin-top: -20px;
// }

.popupWrapper {
	position: relative;
}

.casesButton {
	display: flex;
	align-items: center;
	justify-content: center;
	//   text-align: center;
	width: 206px;
	height: 50px;
	font-size: 20px;
	font-weight: 600;
	background: transparent;
	border-radius: 15px;
	border: 2px solid black;
	margin-top: 50px;

	img {
		margin-left: 10px;
	}

	&:hover {
		cursor: pointer;
	}
}

.activeButton {
	background: #c8d9e9;
	border: 2px solid #c8d9e9;
}

.popup {
	position: absolute;
	left: 0px;
	top: 51px;
	background: #ffffff;
	box-shadow: 0px 5px 26px rgb(0 0 0 / 17%);
	border-radius: 8px;
	padding: 10px 0;
	z-index: 5;
	opacity: 0;
	visibility: hidden;
	transition: height 0.5s, opacity 0.3s, visibility 0.5s;
	height: 0;

	li {
		font-size: 24px;
		font-weight: 500;
		line-height: 2.2rem;
		padding: 0 17px;

		&:hover {
			background-color: #c8d9e9;
			color: black;
			cursor: pointer;
		}
	}
}

.popupActive {
	position: absolute;
	left: 0px;
	top: 51px;
	background: #ffffff;
	box-shadow: 0px 5px 26px rgb(0 0 0 / 17%);
	border-radius: 8px;
	padding: 10px 0;
	z-index: 5;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.5s, height 0.3s;
	height: 231px;
}

.activeType {
	background-color: #272727;
	color: #fff;
}

.disappear {
	display: none;
	animation: sucess-status 0.3s linear;
}

.item1,
.item2,
.item3,
.item4,
.item5,
.item6,
.item7,
.item8,
.item9,
.item10,
.item11,
.item12,
.item13 {
	overflow: hidden;
	border-radius: 25px;
}

// .item1 {
//   grid-area: item1;
// }
// .item2 {
//   grid-area: item2;
// }
// .item3 {
//   grid-area: item3;
// }
// .item4 {
//   grid-area: item4;
// }
// .item5 {
//   grid-area: item5;
// }
// .item6 {
//   grid-area: item6;
// }
// .item7 {
//   grid-area: item7;
// }
// .item8 {
//   grid-area: item8;
// }
// .item9 {
//   grid-area: item9;
// }

.cardContainer {
	margin-top: 40px;
	display: grid;
	gap: 35px;
	//   grid-template-areas: 'item1 item2 item3' 'item4 item5 item6' 'item7 item9 item9' 'item8 item9 item9';
	padding: 10px;

	grid-template-columns: repeat(3, 1fr);

	a {
		position: relative;
		// max-width: 630px;
		// width: 430px;
		// height: 292px;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: all 0.8s;

		&:hover {
			transform: scale(1.2);
		}
	}

	span {
		position: absolute;
		right: 17px;
		bottom: 19px;
		font-size: 24px;
		color: #fff;
		border-radius: 22px;
		padding: 8px 18px;
		background-color: #272727;
	}
}

.form {
	display: flex;
	margin-top: 50px;
}

@media screen and (min-width: 2100px) {
	.cardContainer {
		grid-template-columns: repeat(4, 1fr);
	}
}

@media screen and (min-width: 2400px) {
	.cardContainer {
		grid-template-columns: repeat(5, 1fr);
	}
}

@media screen and (min-width: 2700px) {
	.cardContainer {
		grid-template-columns: repeat(6, 1fr);
	}
}

@media screen and (min-width: 3000px) {
	.cardContainer {
		grid-template-columns: repeat(7, 1fr);
	}
}

@media screen and (max-width: 1300px) {
	.form {
		display: block;
		margin-top: 40px;
	}
}

@media screen and (max-width: 1000px) {
	.casesButton {
		margin-top: 0;
	}

	.cardContainer {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 800px) {
	.cardContainer {
		span {
			font-size: 20px;
		}
	}

	.form {
		margin-top: 0;
	}
}

@media screen and (max-width: 576px) {
	.cardContainer {
		grid-template-columns: repeat(1, 1fr);

		a {
			width: 80%;
			margin: 0 auto;
		}
	}
}

@media screen and (max-width: 415px) {
	.cardContainer {
		a {
			width: 100%;
		}
	}
}
