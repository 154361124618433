.topSection {
  position: relative;
}

.image {
  position: relative;
  margin-left: -50px;
  margin-top: -15px;
  margin-right: -50px;
  border-radius: 50px 0 0 0;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 562px;
    object-fit: cover;
  }
  h1 {
    position: absolute;
    bottom: 0;
    left: 70px;
    color: rgb(255, 255, 255);
    font-size: 80px;
    // background-color: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
    padding: 5px 10px;
    display: inline-block;
  }
}

.description {
  margin-top: 55px;
  position: relative;
  height: calc(100% + 100px);
  //   overflow: hidden;
  h3 {
    font-size: 40px;
    margin-top: 40px;
  }

  article {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7rem;
    max-width: 1000px;
  }
}

.topWrapper {
  margin-top: 30px;
  display: flex;
  gap: 25px;
  max-width: 2320px;
  img {
    width: 17%;
    height: 80%;
  }
  img:nth-child(2n + 2) {
    margin-top: 141px;
  }
}

.imageText {
  max-width: 2300px;
  p {
    font-size: 22px;
    font-weight: 400;
    color: #a8a8a8;
  }
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

.botWrapper {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  gap: 30px;

  img {
    width: 40%;
    max-width: 827px;
  }

  p {
    font-size: 22px;
    font-weight: 400;
    color: #a8a8a8;
  }
}

.form {
  display: flex;
  margin-top: 50px;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .form {
    display: flex;
    flex-direction: column;
    // top: 350px;
  }

  .contacts {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .image {
    h1 {
      width: 89%;
    }
  }

  .topWrapper {
    margin-top: 30px;
    display: flex;
    gap: 25px;
    img {
      width: 17%;
      height: 80%;
    }
    img:nth-child(2n + 2) {
      margin-top: 100px;
    }
  }
}

@media screen and (max-width: 800px) {
  .image {
    h1 {
      width: 82%;
      font-size: 56px;
    }
  }

  .description {
    article {
      font-size: 22px;
    }
  }

  .topImgWrapper {
    flex-direction: column;
    align-items: center;
  }

  .botWrapper {
    img {
      width: 50%;
    }
  }
}

@media screen and (max-width: 576px) {
  .image {
    h1 {
      left: 47px;
      width: 79%;
      font-size: 46px;
      bottom: -21px;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      margin-top: 15px;
      font-size: 19px;
    }
    h3 {
      font-size: 38px;
    }
  }

  .topWrapper {
    flex-direction: column;
    img {
      width: 100%;
    }
    img:nth-child(2n + 2) {
      margin-top: 0;
    }
  }

  .botWrapper {
    flex-direction: column;
    gap: 20px;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 415px) {
  .image {
    h1 {
      width: 76%;
      font-size: 34px;
      bottom: 0px;
    }
  }

  .topWrapper {
    gap: 25px;

    img {
      height: 100%;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      font-size: 18px;
    }
    h3 {
      font-size: 34px;
    }
  }
}
