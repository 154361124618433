.topSection {
	position: relative;
}

.image {
	position: relative;
	margin-left: -50px;
	margin-top: -15px;
	margin-right: -50px;
	border-radius: 50px 0 0 0;
	overflow: hidden;

	img {
		width: 100%;
		max-height: 562px;
		object-fit: cover;
	}
	h1 {
		position: absolute;
		bottom: 0;
		left: 70px;
		color: rgb(255, 255, 255);
		font-size: 65px;
		// background-color: rgba(0, 0, 0, 0.6);
		border-radius: 25px;
		padding: 5px 10px;
		display: inline-block;
	}
}

.bgOne__wrapper,
.bgTwo__wrapper,
.bgThree__wrapper {
	z-index: 10;
	position: relative;
	margin-left: -50px;
	margin-right: -50px;
	margin-top: -5px;

	img {
		width: 100%;
		object-fit: cover;
	}

	.topText {
		position: absolute;
		top: 60%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 20px;
		font-weight: 500;
		line-height: 140%;
		max-width: 885px;
		width: 100%;
	}
}

.bgTwo__wrapper {
	margin-top: -28px;
	z-index: 5;
	img {
		width: 100%;
		object-fit: cover;
	}
}

.bgThree__wrapper {
	img {
		width: 100%;
		object-fit: cover;
	}

	.bottomText {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 20px;
		font-weight: 500;
		line-height: 140%;
		width: 100%;
		max-width: 920px;
	}
}

.description {
	// .topText {
	// 	margin: 288px auto 0 auto;
	// 	font-size: 20px;
	// 	font-weight: 500;
	// 	line-height: 140%;
	// 	max-width: 885px;
	// }

	.videoWrapper {
		width: 100%;
		height: 600px;
		video {
			margin-top: 25px;
			width: 60%;
			// height: 400px;
			// flex: 1.5;
			height: auto;
			box-shadow: 0px 10px 15px 4px rgba(0, 0, 0, 0.4);
		}
	}
}

.form {
	display: flex;
	margin-top: 50px;
}

@media screen and (max-width: 1900px) {
}

@media screen and (max-width: 1300px) {
	.form {
		display: block;
		top: 350px;
	}

	.contacts {
		margin-bottom: 30px;
	}

	.bgOne__wrapper,
	.bgTwo__wrapper,
	.bgThree__wrapper {
		.topText {
			max-width: 800px;
		}

		.bottomText {
			max-width: 695px;
		}
	}
}

@media screen and (max-width: 1000px) {
	.image {
		border-radius: 0 0 0 0;
		h1 {
			font-size: 48px;
		}
	}

	.bgOne__wrapper,
	.bgTwo__wrapper,
	.bgThree__wrapper {
		.topText {
			max-width: 67%;
			font-size: 19px;
		}

		.bottomText {
			max-width: 67%;
			font-size: 19px;
		}
	}

	.description {
		.videoWrapper {
			height: 400px;
			video {
				width: 80%;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.image {
		h1 {
			font-size: 45px;
		}
	}

	.bgOne__wrapper,
	.bgTwo__wrapper,
	.bgThree__wrapper {
		.topText {
			max-width: 67%;
			font-size: 15px;
		}

		.bottomText {
			max-width: 67%;
			font-size: 15px;
		}
	}

	.description {
		.videoWrapper {
			height: 300px;
			flex-direction: column;
			// align-items: center;

			video {
				//   flex: 1;
				width: 80%;
				margin: 25px auto;
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.image {
		h1 {
			font-size: 33px;
		}
	}

	.bgOne__wrapper,
	.bgTwo__wrapper,
	.bgThree__wrapper {
		.topText {
			max-width: 78%;
			font-size: 11px;
		}

		.bottomText {
			max-width: 78%;
			font-size: 11px;
		}
	}

	.description {
		.videoWrapper {
			height: 250px;
			video {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.image {
		h1 {
			font-size: 29px;
		}
	}

	.bgOne__wrapper,
	.bgTwo__wrapper,
	.bgThree__wrapper {
		.topText {
			max-width: 78%;
			font-size: 8px;
		}

		.bottomText {
			max-width: 78%;
			font-size: 8px;
		}
	}
	.description {
		.videoWrapper {
			height: 200px;
		}
	}
}
