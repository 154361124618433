.topSection {
	position: relative;
}

.image {
	position: relative;
	margin-left: -50px;
	margin-top: -15px;
	margin-right: -50px;
	border-radius: 50px 0 0 0;
	overflow: hidden;
	img {
		width: 100%;
		max-height: 562px;
		object-fit: cover;
	}
	h1 {
		position: absolute;
		bottom: 0;
		left: 70px;
		color: #fff;
		font-size: 65px;
		// background-color: rgba(0, 0, 0, 0.3);
		border-radius: 25px;
		padding: 5px 10px;
		display: inline-block;
	}
}

.description {
	.subheader {
		font-size: 45px;
		line-height: 3rem;
		font-weight: 700;
		margin: 50px 0 20px 0;
	}

	p {
		font-size: 22px;
		font-weight: 400;
		width: 70%;
		margin: 20px 0 0 0;
	}

	.wrapper3d {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		gap: 20px;

		img {
			width: 418px;
			height: 233px;
			margin-bottom: 25px;
		}
	}

	.wrapperStill {
		margin-top: 40px;
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		gap: 20px;
	}

	.imgWrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		span {
			margin: 10px 0 35px 0;
			font-size: 16px;
		}
	}

	.scenesWrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 42px 25px;
		justify-content: space-around;
	}

	.nyWrapper {
		margin-top: 35px;
		display: flex;
		flex-wrap: wrap;
		gap: 42px 25px;
	}

	.videoWrapper {
		display: flex;
		gap: 150px;

		p {
			// flex: 0.8;
			line-height: 1.9rem;
			max-width: 1000px;
		}

		.video {
			background: url(../assets/loader_2.gif) center center no-repeat;
			// width: 90%;
			// height: 100%;
			flex: 1.5;
			width: 794px;
			height: 435px;
			// height: auto;
			// box-shadow: 0px 10px 15px 4px rgba(0, 0, 0, 0.4);
		}
	}
}

.nyWrapper {
	img {
		width: 45%;
		max-width: 630px;
	}
}

.form {
	display: flex;
	margin-top: 50px;
}

@media screen and (min-width: 2000px) {
	.description {
		.wrapper3d,
		.wrapperStill {
			justify-content: start;
		}
	}
}

@media screen and (max-width: 1900px) {
	.scenesWrapper {
		justify-content: space-between;
	}
}

@media screen and (max-width: 1400px) {
	.description {
		.videoWrapper {
			.video {
				width: 640px;
				height: 300px;
			}
		}
	}
}

@media screen and (max-width: 1300px) {
	.form {
		display: block;
		top: 350px;
	}

	.images {
		&_left {
			gap: 35px;
		}

		&_right {
			gap: 15px;
		}
	}

	.description {
		.videoWrapper {
			gap: 0;
			justify-content: space-between;

			// p {
			//   flex: 2;
			// }

			.video {
				gap: 0;
			}
		}
	}

	.contacts {
		margin-bottom: 30px;
	}

	// .article {
	//   h1 {
	//     font-size: 44px;
	//   }
	// }
}

@media screen and (max-width: 1000px) {
	.image {
		h1 {
			font-size: 48px;
		}
	}

	.description {
		p {
			line-height: 1.7rem;
		}
		.videoWrapper {
			display: flex;
			// gap: 20px;

			gap: 60px;
			flex-direction: column;

			p {
				font-size: 20px;
				line-height: 1.7rem;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.image {
		h1 {
			font-size: 45px;
			width: 80%;
		}
	}

	.description {
		.subheader {
			font-size: 40px;
		}

		p {
			width: 90%;
			font-size: 21px;
		}

		.nyWrapper {
			img {
				width: 100%;
			}
		}

		.videoWrapper {
			flex-direction: column;
			// align-items: center;

			.video {
				//   flex: 1;
				// margin: 25px auto;

				width: 100%;
			}
			p {
				// flex: 1;

				width: 100%;
			}
		}

		article {
			font-size: 22px;
		}
	}
}

@media screen and (max-width: 576px) {
	.image {
		h1 {
			font-size: 33px;
			width: 70%;
		}
	}

	.description {
		.subheader {
			font-size: 37px;
			margin: 26px 0 6px 0;
		}

		p {
			width: 100%;
		}

		.wrapper3d {
			img {
				width: 100%;
				height: auto;
			}
		}

		.scenesWrapper {
			img {
				width: 100%;
			}
		}

		.videoWrapper {
			.video {
				width: 100%;

				background-size: 30% auto;
			}
		}
	}
}

@media screen and (max-width: 415px) {
	.image {
		h1 {
			font-size: 30px;
		}
	}

	.description {
		.subheader {
			font-size: 33px;
		}

		p {
			width: 100%;
			font-size: 20px;
		}

		.videoWrapper {
			gap: 0;
		}

		.imgWrapper {
			img {
				width: 100%;
			}
		}
	}
}
