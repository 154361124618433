.wrapper {
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.img_wrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0 0 30px #fff, 0 0 40px #fff, 0 0 55px #fff, 0 0 75px #c8d9e9, 2px 2px 2px rgba(255, 225, 219, 0.503);
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.flags_wrapper {
  position: absolute;
  right: 0;
  top: 52px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 0;
  opacity: 0;
  transition: all 0.5s;
  width: 100px;
  z-index: 5;
  height: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 1px;
    right: 10px;
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    transform: translateY(-50%) rotate(45deg);

    width: 15px;
    height: 0;
  }
}
.active {
  position: absolute;
  right: 0;
  top: 52px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 0;
  z-index: 5;
  opacity: 1;
  transition: all 0.4s;
  height: 230px;
  &:before {
    height: 15px;
    transition: 0.3s;
  }

  .icon {
    opacity: 1;
    transition: 0.5s;
    transition-delay: 0.2s;
    transform: scale(1);
  }
}

.icon {
  display: block;
  margin: auto;
  width: 70px;
  height: 70px;

  opacity: 0;
  transition: 0.4s;
  transform: scale(0);
  filter: grayscale(0.15);
  &:hover {
    transform: scale(1.1);
    filter: grayscale(0);
    transition: all 0.2s ease-in;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
