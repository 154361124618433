.wrapper {
}
.topSection {
  position: relative;
}
.image {
  margin-left: -50px;
  margin-top: -15px;
  margin-right: -50px;
  border-radius: 50px 0 0 0;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 562px;
    object-fit: cover;
  }
}

.article {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  bottom: 21px;
  left: -15px;

  h1 {
    font-size: 60px;
    // background-color: rgba(255, 255, 255, 0.6);
    border-radius: 25px;
    padding: 5px 10px;
    display: inline-block;
  }
}

.icons {
  display: flex;

  &_wrapper {
    margin: 0 20px;
    width: 50px;
    height: 50px;
    border: 2px solid black;
    padding: 10px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.description {
  margin-top: 55px;
  position: relative;
  height: calc(100% + 100px);
  //   overflow: hidden;
  h3 {
    font-size: 40px;
  }

  article {
    margin-top: 30px;
    font-size: 20px;
    line-height: 1.7rem;
  }
}

.graffiti_wrapper {
  img {
    max-width: 2000px;
    width: 90%;
    object-fit: cover;
  }
}

.markup {
  display: flex;
  margin-top: 30px;
  margin-left: -50px;
  margin-right: -50px;
  overflow: hidden;
  flex-direction: row-reverse;
  &_one {
    min-width: 590px;
    height: 226px;
    //  margin-left: 20px;
    //  background-color: #4eabe9;
    //  border-radius: 132px;

    img {
      width: 102%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_two,
  &_three,
  &_four {
    min-width: 226px;
    height: 226px;
    background-color: rgba($color: #4eabe9, $alpha: 0.8);
    border-radius: 132px;
    //  margin: 0 25px;
    margin-right: 50px;
  }

  &_three {
    background-color: rgba($color: #4eabe9, $alpha: 0.6);
  }

  &_four {
    background-color: rgba($color: #4eabe9, $alpha: 0.4);
    //  position: absolute;
    //  top: 0px;
    //  left: -10px;
  }
}

.logo_images,
.graffiti_img {
  margin-top: 30px;
  margin-left: -50px;
  margin-right: -50px;
  //   margin-top: -15px;
  //   width: 100vw;

  img {
    width: 100%;
    object-fit: cover;
    max-width: 2472px;
  }
}

.graffiti_img {
  img {
    border-radius: 60px 0 0 0;
  }
}

.form {
  display: flex;
  // position: absolute;
  // bottom: 50px;
  // left: 50px;
  margin-top: -300px;
  position: relative;
  z-index: 1;
}

// @media screen and (min-width: 2500px) {
//   .logo_images,
//   .graffiti_img {
//     margin: 0 auto;
//   }
// }

@media screen and (max-width: 1300px) {
  .wrapper {
    padding-bottom: 222px;
  }

  .form {
    display: block;
    // top: 350px;
  }

  .contacts {
    margin-bottom: 30px;
  }

  .icons {
    &_wrapper {
      width: 40px;
      height: 40px;
      padding: 8px;
      margin: 0 13px;
    }
  }

  .article {
    h1 {
      font-size: 44px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {
    margin-bottom: 140px;
  }

  .article {
    width: 106%;
    h1 {
      font-size: 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    margin-bottom: 769px;
  }
  .article {
    width: 100%;
    flex-direction: column;
    bottom: -50px;

    h1 {
      font-size: 38px;
    }
  }
  .logo_images,
  .image,
  .markup {
    margin-right: -10px;
  }

  .graffiti_img {
    margin-right: -10px;
  }

  .form {
    left: 0;
  }
}

@media screen and (max-width: 576px) {
  .article {
    bottom: -55px;
    h1 {
      margin: 0 0 9px 11px;
    }
  }

  .icons {
    margin-top: 20px;
  }

  // .description {
  //   h3:nth-child(1) {
  //     margin-top: 75px;
  //   }
  // }

  .markup {
    &_one {
      min-width: 290px;
      height: 116px;
      img {
        width: 107%;
      }
    }

    &_two,
    &_three,
    &_four {
      min-width: 116px;
      height: 116px;
    }
  }

  .form {
    margin-top: -150px;
  }

  .wrapper {
    margin-bottom: 962px;
  }
}

@media screen and (max-width: 415px) {
  .article {
    h1 {
      font-size: 34px;
    }
  }
  .description {
    article {
      margin-top: 13px;
    }
  }
}
