.wrapper {
  background-color: #fff;
  text-align: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  position: fixed;
}

.text {
  // position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  font-size: 100px;
  font-weight: 700;
  text-align: center;
}

.fixed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Установите значение z-index по вашему усмотрению */
  /* Дополнительные стили по вашему выбору */
}


@media screen and (max-width:1000px) {
  .text {
   font-size: 70px;
  }
 
}

@media screen and (max-width: 768px) {
  .text {
   font-size: 50px;
  }
}

@media screen and (max-width: 425px) {
  .text {
   font-size: 44px;
  }
}