.topSection {
  position: relative;
}

.image {
  position: relative;
  margin-left: -50px;
  margin-top: -15px;
  margin-right: -50px;
  border-radius: 50px 0 0 0;
  overflow: hidden;
  img {
    width: 100%;
    max-height: 562px;
    object-fit: cover;
  }
  h1 {
    position: absolute;
    bottom: 0;
    left: 70px;
    color: rgb(255, 255, 255);
    font-size: 80px;
    // background-color: rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    padding: 5px 10px;
    display: inline-block;
  }
}

.description {
  margin-top: 55px;
  position: relative;
  height: calc(100% + 100px);
  //   overflow: hidden;
  h3 {
    font-size: 40px;
    margin-top: 35px;
  }

  article {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.7rem;
    max-width: 1000px;
  }
}

.botImage {
  margin-top: 30px;
  img {
    width: 100%;
    max-width: 1750px;
  }
}

.form {
  display: flex;
  margin-top: 50px;
}

@media screen and (max-width: 1300px) {
  .form {
    display: flex;
    flex-direction: column;
    // top: 350px;
  }

  .contacts {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 800px) {
  .image {
    h1 {
      left: 47px;
      width: 86%;
      font-size: 66px;
      bottom: -15px;
    }
  }
  .description {
    article {
      font-size: 22px;
    }
  }

  .botImage {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 576px) {
  .image {
    h1 {
      font-size: 45px;
      width: 80%;
    }
  }

  .description {
    margin-top: 25px;
    //   overflow: hidden;
    article {
      margin-top: 15px;
      font-size: 19px;
    }
    h3 {
      font-size: 38px;
    }
  }

  .botWrapper {
    flex-direction: column;
    gap: 30px;
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 415px) {
  .image {
    h1 {
      font-size: 36px;
      width: 76%;
    }
  }

  .description {
    margin-top: 25px;
    article {
      font-size: 18px;
    }
    h3 {
      font-size: 34px;
    }
  }
}
