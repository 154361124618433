.wrapper {
	max-width: 100vw;
	padding: 23px 30px 30px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	//
	// position: relative;

	&_reversed {
		flex-direction: row-reverse;
	}
}

.first__section {
	display: flex;
	align-items: center;

	&_reversed {
		flex-direction: row-reverse;
	}
}

.bar {
	&__bigwrapper {
		display: flex;
	}
	&__wrapper:hover {
		cursor: pointer;
	}
}

.bar1,
.bar2,
.bar3 {
	width: 35px;
	height: 3px;
	background-color: #333;
	margin: 6px 0;
	transition: 0.4s;
}

/* Rotate first bar */
.change1 {
	transform: translate(0, 11px) rotate(-45deg);
}

/* Fade out the second bar */
.change2 {
	opacity: 0;
}

/* Rotate last bar */
.change3 {
	transform: translate(0, -8px) rotate(45deg);
}

// language button
.flags {
	display: none;
	margin-right: 25px;
}

.sections {
	margin-left: 50px;
	display: flex;
	align-items: center;
	//
	position: relative;

	&_reversed {
		flex-direction: row-reverse;
		margin-left: 0;
		margin-right: 50px;
	}
}

.design {
	display: flex;
	align-items: center;
	font-size: 25px;
	font-weight: 800;
	margin: 0 30px;
	color: #000;

	img {
		width: 160px;
	}
}

// .sections :global(a.active) {
//   transition: text-shadow 0.5s ease 0s;
//   border-bottom: 4px solid #fff;
//   // text-shadow: 1px 2px 5px #c8d9e9;
//   text-shadow: 0 0 30px #fff, 0 0 40px #fff, 0 0 55px #fff, 0 0 75px #c8d9e9, 2px 2px 2px rgba(255, 225, 219, 0.503);
// }
.sections :global(a.active) {
	transition: all 0.5s ease 0s;
	text-shadow: 0 0 30px #fff, 0 0 40px #fff, 0 0 55px #fff, 0 0 75px #c8d9e9,
		2px 2px 2px rgba(255, 225, 219, 0.703);
}

.services {
	font-size: 25px;
	font-weight: 500;
	margin: 0 30px;
	color: #000;
	line-height: 45px;

	//
	position: relative;
}

// начало - это стили для активного элемента
.sections .marker {
	position: absolute;
	height: 4px;
	width: 0; /* вот эти значения меняем динамически */
	left: 0; /* вот эти значения меняем динамически */
	background: #000;
	bottom: -8px;
	transition: 0.5s;
	border-radius: 4px;
}
// конец

.second__section {
	display: flex;
	align-items: center;
	// align-items: center;

	&_reversed {
		flex-direction: row-reverse;
	}
}

.button {
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 10px 17px;
	background-color: #000;
	color: #fff;
	font-size: 20px;
	border-radius: 18px;
	margin-right: 30px;

	&_reversed {
		margin-right: 0;
		margin-left: 30px;
	}
}

@media screen and (max-width: 1000px) {
	.sections {
		margin-left: 0;

		.marker {
			background: #c8d9e9;
		}
	}

	// .sections :global(a.active) {
	//   border-bottom: 4px solid #c8d9e9;
	//   // text-shadow: 1px 2px 5px #c8d9e9;
	//   text-shadow: 0 0 30px #c8d9e9, 0 0 40px #c8d9e9, 0 0 55px #c8d9e9, 0 0 75px #c8d9e9,
	//     2px 2px 2px rgba(255, 225, 219, 0.503);
	// }

	.design {
		font-size: 18px;
	}

	.services {
		font-size: 20px;
		margin: 0 10px;
	}
}

@media screen and (max-width: 750px) {
	.button {
		padding: 10px 4px;
		font-size: 20px;
		margin-right: 22px;
		width: 153px;
	}
}

@media screen and (max-width: 700px) {
	.wrapper {
		padding-bottom: 0;
	}

	.design {
		overflow: hidden;
	}

	.first__section {
		width: 100%;
		flex-direction: row-reverse;
		justify-content: space-between;
	}

	.flags {
		display: block;
	}

	.marker {
		display: none;
	}

	.design {
		margin: 0;
	}
	.services {
		display: none;
	}

	.second__section {
		display: none;
	}
}

@media screen and (max-width: 415px) {
	.design {
		margin-left: -25px;
	}
}
